(function($) {
	var postId = parseInt(psqh.postId);

	if ( postId ) {
		$.post(psqh.ajaxurl, {
			action: 'increment-post-hit-count',
			post_id: postId
		}, function(data) {
			console.log(data);
		});
	}

	$('.slider').unslider({
		autoplay: true,
		delay: 7000,
		arrows: {
			prev: '<a class="unslider-arrow prev">&lsaquo;</a>',
			next: '<a class="unslider-arrow next">&rsaquo;</a>'
		},
		animation: 'fade'
	});

})(jQuery);
